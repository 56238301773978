































































import { Component, Vue } from 'vue-property-decorator';
import { getHistoryForm } from '@/api/form-design/FormApi';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import ReportConfig from '@/components/report-design/report-config.vue';
interface FlowListColumns {
  title: string;
  key?: string;
  slot?: string;
  align?: string;
}

@Component({
  name: 'FormHistory',
  components: {
    ReportConfig
  }
})
export default class FormHistory extends Vue {
  DateUtil: any = DateUtil;
  
  reportConfigDiglogVisible: boolean = false;
  appId: any = '';
  releaseId: string = '';
  reportId: string = '';
  type: any = '';
  // 表单搜索Model
  formListColumns: FlowListColumns[] = [
    {
      title: this.$t('lang_name') as string,
      key: 'link.displayName'
    },
    {
      title: this.$t('lang_code') as string,
      key: 'link.code'
    },

    {
      title: this.$t('lang_edition') as string,
      key: 'link.version'
    }
  ];
  formListData: Record<string, any>[] = [];
  lid: any = null;

  created() {
    this.lid = this.$route.query.lid;
    this.appId = this.$route.query.applicationId;
    this.type = this.$route.query.type;
    this.getHistoryForm();
  }

  // 获取表单列表
  getHistoryForm(): void {
    getHistoryForm(this.lid).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.formListData = res.data;
      }
    });
  }

  // 数据实例
  design(row) {
    if (this.type == 'readonly') {
      this.reportId = row.link.id;
      this.releaseId = row.id;
      this.reportConfigDiglogVisible = true;
    } else {
      this.$router.push({ path: '/form/design', query: { formId: row.link.id } });
    }
  }
  closeDialog() {
    this.reportConfigDiglogVisible = false;
  }
  /**
   * 返回应用管理列表
   */
  back() {
    this.$router.go(-1);
  }
}
